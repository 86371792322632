var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            this.type === "view"
              ? _vm.$t("cip.plat.sync.user.title.viewHeadTitle")
              : this.type === "add"
              ? _vm.$t("cip.plat.sync.user.title.addHeadTitle")
              : _vm.$t("cip.plat.sync.user.title.editHeadTitle"),
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave()
          },
          "head-save-cancel": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("form-layout", {
        ref: "formLayout",
        attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
      }),
      _c(
        "div",
        { staticStyle: { "margin-top": "12px" } },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.dataForm.sysRefs,
                size: "medium",
                height: "calc(100vh - 624px)",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: "center",
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "orgName", label: "公司", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "deptName", label: "部门", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "positionName", label: "岗位", align: "center" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }